import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '49, 27, 146',
		'primary-dark': '18, 0, 94',
		'accent': '0, 200, 83',
		'accent-plus': '0, 0, 0',
	},
});
